
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useTable, { IFilterOptions, IRefreshTable } from "@core/utils/useTable";
import {
  BCard,
  BCol,
  BFormInput,
  BModal,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Ref, ref, watch } from "vue";
import { Component, Vue } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import GroupEconomicHandler from "../group-economic-handler/GroupEconomicHandler.vue";

// Services
import { useAuth, useGroupEconomic } from "@/services";

// Interfaces
import {
  IBodyListGroupEconomic,
  IGroupEconomicApi,
  IGroupEconomicDeleteResponse,
  IResponseListGroupEconomic,
} from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

@Component({
  name: "GroupsEconomicList",
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BPagination,
    BFormInput,
    BTable,
    AvButton,
    AvExportButton,
    GroupEconomicHandler,
    AvSkeletonTable,
    BModal,
  },
  directives: {
    Ripple,
  },
})
export default class GroupsEconomicList extends Vue {
  // Data
  domain: string | null = useAuth.getDomain();
  isGroupEconomicSidebarActive: Ref<boolean> = ref(false);
  statusOptions: Array<{ label: string; value: boolean }> = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];
  tableColumns = [
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Codigo", label: "Código", sortable: true },
    { key: "Ativo", label: "Status", sortable: true },
    { key: "actions", label: "Ações" },
  ];

  useTable = new useTable(
    this.tableColumns,
    {
      sortBy: "Nome",
    },
    this.fetchListGroupsEconomic
  );
  groupEconomicId: number | null = null;
  groupsEconomic: Array<IGroupEconomicApi> = [];
  modalConfirmShow: boolean = false;
  loadingDelete: boolean = false;
  groupEconomicDeletId: number | null = null;

  // Watchs
  watchData = watch(
    [
      this.useTable.currentPage,
      this.useTable.perPage,
      this.useTable.searchQuery,
    ],
    () => {
      this.refreshData();
    }
  );

  // Methods
  fetchList(ctx: Vue, callback: Function) {
    this.useTable.fetchList(callback);
  }

  refreshData() {
    // @ts-ignore
    this.$refs.refListTable.refresh();
  }

  mapFilterToBody(options: IFilterOptions): IBodyListGroupEconomic {
    let body = this.useTable.mapFilterToBody(options);

    return body;
  }

  async fetchListGroupsEconomic(
    options: IFilterOptions
  ): Promise<IRefreshTable> {
    return await useGroupEconomic
      .requestList(this.mapFilterToBody(options))
      .then((response: IResponseListGroupEconomic) => {
        const data = response.data;

        return {
          data: data.data,
          total: data.recordsTotal,
          display: data.recordsFiltered,
        };
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar grupos econômicos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return {
          data: [],
          total: 0,
          display: 0,
        };
      });
  }

  clearGroupEconomicData = () => {
    this.groupEconomicId = null;
  };

  /**
   * Essa função é acionada quando queremos abrir o modal de editar grupo
   */
  handlerSidebarGroupEconomic(id: number | null): void {
    this.isGroupEconomicSidebarActive.value = true;
    this.groupEconomicId = id;
  }

  updateIsGroupEconomicSidebarActive = (val: boolean): void => {
    this.isGroupEconomicSidebarActive.value = val;
  };

  /**
   * Abre o modal de confirmação de exclusão da biblioteca
   */
  async showConfirmationDelete(id: number) {
    this.modalConfirmShow = true;
    this.groupEconomicDeletId = id;
  }

  deleteGroupEconomic() {
    if (this.groupEconomicDeletId) {
      this.loadingDelete = true;
      useGroupEconomic
        .requestDelete(this.groupEconomicDeletId)
        .then((response: IGroupEconomicDeleteResponse) => {
          if (response.status == 200 || response.status == 201) {
            this.refreshData();
            this.groupEconomicDeletId = null;
            this.modalConfirmShow = false;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao tentar apagar grupos econômicos!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }

  getEconomicGroups() {
    return new Promise((resolve, reject) => {
      let body = this.mapFilterToBody({
        perPage: this.useTable.perPage.value,
        currentPage: this.useTable.currentPage.value,
        searchQuery: this.useTable.searchQuery.value,
        sortyBy: this.useTable.sortBy.value,
        isSortDirDesc: this.useTable.isSortDirDesc.value,
        filters: this.useTable.filters,
      });

      body.length = 500000;

      useGroupEconomic
        .requestList(body)
        .then((response: IResponseListGroupEconomic) => {
          const data = response.data.data.map((group: IGroupEconomicApi) => ({
            Nome: group.Nome,
            Código: group.Codigo,
            Ativo: group.Ativo ? "Ativo" : "Inativo",
          }));

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
