
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
// Services
import { useGroupEconomic } from "@/services";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Interfaces
import {
  IGroupEconomicUpdateResponse,
  IResponseGetGroupEconomic,
} from "@core/services/interfaces/business/group-economic/IGroupEconomicService";
import { required } from "@validations";
import { Ref, ref } from "@vue/composition-api";
import {
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BSidebar,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

interface IGroupEconomic {
  id?: number;
  name: string;
  code: number;
  status: boolean;
}

@Component({
  name: "GroupEconomicHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    AvButton,
    vSelect,
    flatPickr,
    AvSkeletonInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isGroupEconomicSidebarActive",
    event: "update:is-group-economic-handler-sidebar-active",
  },
})
export default class GroupEconomicHandler extends Vue {
  // Props
  @Prop({ required: true, default: false })
  isGroupEconomicSidebarActive!: Ref<boolean>;
  @Prop({ required: true, default: [] }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;
  @Prop({ required: true }) groupEconomicId!: number;
  @Prop({ required: true, default: () => {} })
  clearGroupEconomicData!: () => void;

  // Data
  groupTitle =
    this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE ||
    "Grupo Empresa";
  required = required;
  loading: boolean = false;
  saving: boolean = false;
  blankGroupEconomic: IGroupEconomic = {} as IGroupEconomic;
  groupEconomicLocal: Ref<IGroupEconomic> = ref(this.blankGroupEconomic);
  formValidation = formValidation(this.resetGroupEconomicLocal, this.clearForm);

  // Computeds
  get isSidebarActive(): boolean {
    return this.isGroupEconomicSidebarActive.value;
  }

  // Watchs
  @Watch("groupEconomicId", { immediate: true })
  reset() {
    if (typeof this.groupEconomicId == "number") {
      this.resetGroupEconomicLocal();
    } else {
    }
  }

  // ------------------------------------------------
  // isGroupEconomicSidebarActive
  // * Limpar formulário se a barra lateral estiver fechada
  // * Consultar o grupo econômico se tiver passando o ID
  // ------------------------------------------------
  @Watch("isSidebarActive")
  async handleSidebar(val: boolean) {
    // ? Não redefina o evento até que a transição seja concluída
    if (!val) {
      setTimeout(() => {
        this.clearForm();
      }, 350);
    }

    if (this.isSidebarActive && this.groupEconomicId) {
      this.loading = true;
      await useGroupEconomic
        .requestGetById(this.groupEconomicId)
        .then((response: IResponseGetGroupEconomic) => {
          this.groupEconomicLocal.value = {
            id: response.data.Data.Id,
            name: response.data.Data.Nome,
            code: response.data.Data.Codigo,
            status: response.data.Data.Ativo,
          };
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao consultar o grupo econômico!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  // Methods
  resetGroupEconomicLocal() {
    this.groupEconomicLocal.value.id = this.groupEconomicId;
  }

  clearForm() {
    if (typeof this.clearGroupEconomicData == "function")
      this.clearGroupEconomicData;

    this.groupEconomicLocal.value = this.blankGroupEconomic;
  }

  async onSubmit() {
    this.saving = true;

    if (this.groupEconomicLocal.value.id) {
      await useGroupEconomic
        .requestUpdate({
          id: this.groupEconomicLocal.value.id,
          nome: this.groupEconomicLocal.value.name,
          codigo: this.groupEconomicLocal.value.code,
          ativo: this.groupEconomicLocal.value.status,
        })
        .then((response: IGroupEconomicUpdateResponse) => {
          if (response.data.Status == 200 || response.data.Status == 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.groupTitle + " atualizado com sucesso!",
                icon: "CheckIcon",
                variant: "success",
              },
            });

            this.$emit("refresh-data");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ocorreu um erro inesperado!",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao atualizar grupo econômicos!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    } else {
      await useGroupEconomic
        .requestCreate({
          nome: this.groupEconomicLocal.value.name,
          codigo: this.groupEconomicLocal.value.code,
          ativo: this.groupEconomicLocal.value.status,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Grupo Econômico inserido com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao inserir grupo econômicos!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }

    this.saving = false;
    this.$emit("updateIsGroupEconomicSidebarActive", false);
    this.$emit("refreshData");
  }

  // Functions
  /**
   * Abrir ou fechar o sidebar de edição/criação de grupo econômico
   */
  toggleSidebarGroupEconomicHandler = (val: boolean): void => {
    this.$emit("updateIsGroupEconomicSidebarActive", val);
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };
}
